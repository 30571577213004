<template>
  <div v-if="status" id="user-list">
    <customer-list v-if="userData.profile === '30' && $vuetify.breakpoint.xsOnly" :style-theme="false" />

    <!-- app drawer -->
    <glass-add-edit v-model="isAddEditGlassSidebar" />
    <lens-add-edit v-model="isAddEditLensSidebar" />
    <other-products-add-edit v-model="isAddEditOtherProductsSidebar" />

    <!-- list filters -->
    <v-card rounded="xl" :color="$vuetify.breakpoint.xsOnly ? 'white' : 'thead-color'" :disabled="loading">
      <v-card-title class="text-h6" :class="$vuetify.breakpoint.xsOnly ? 'thead-color' : 'form-color'">
        <v-icon left>
          {{ icons.mdiTableLargePlus }}
        </v-icon>
        Sipariş Oluşturma
      </v-card-title>

      <ValidationObserver ref="form" tag="div">
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-if="false"
              v-model="refNo"
              label="Sipariş No"
              class="rounded-0"
              rounded
              filled
              placeholder="Sipariş Referans No"
              hide-details
            ></v-text-field>
            <v-divider v-if="false" />
          </v-col>
          <v-col cols="12">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <v-select
                v-if="false"
                v-model="address"
                label="Sevk Adresi"
                class="rounded-0"
                :menu-props="{ bottom: true, offsetY: true }"
                :loading="addressItems.length === 0"
                :readonly="addressItems.length === 0"
                :items="addressItems"
                :error-messages="errors"
                rounded
                filled
                hide-details
                item-text="address_title"
                item-value="id"
              >
                <template v-slot:selection="{ item }">
                  {{ item.address }} {{ item.state }} {{ item.city.toUpperCase() }}
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title class="text-caption">
                      {{ item.address }} {{ item.state }} {{ item.city.toUpperCase() }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.address_title }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
            </ValidationProvider>
            <v-divider v-if="false" />
          </v-col>

          <v-container fluid class="pa-4" style="background-color: #f5f5f5">
            <v-row>
              <!-- Sevkiyat Yöntemi -->
              <v-col cols="12" md="6">
                <v-card class="pa-4" rounded="xl" outlined>
                  <h4 class="text-subtitle-1 font-weight-bold">Siparişin Sevkiyat Yöntemi</h4>
                  <v-radio-group v-model="shipmentMethod" row>
                    <v-radio label="Şubeye Kargo" value="subeye_kargo"></v-radio>
                    <v-radio label="Müşteriye Kargo" value="musteriye_kargo"></v-radio>
                  </v-radio-group>
                </v-card>
              </v-col>

              <!-- Açıklama Alanı -->
              <v-col cols="12" md="6">
                <v-card class="pa-4" rounded="xl" outlined>
                  <v-textarea
                    v-model="description"
                    label="Açıklama Ekle"
                    placeholder="Sipariş Açıklaması"
                    rounded
                    filled
                    hide-details
                    rows="3"
                    class="white-bg"
                  ></v-textarea>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
      </ValidationObserver>
      <v-row no-gutters>
        <v-col>
          <v-sheet color="primary" height="20">
            <v-sheet
              :color="$vuetify.breakpoint.xsOnly ? 'thead-color' : 'form-color'"
              rounded="b-xl"
              height="20"
            ></v-sheet>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6" md="6">
          <v-btn
            block
            color="primary"
            class="rounded-0 rounded-bl-xl text-caption font-weight-bold"
            x-large
            @click.stop="isAddEditGlassSidebar = !isAddEditGlassSidebar"
          >
            <v-icon left>
              {{ icons.mdiSunglasses }}
            </v-icon>
            Cam Ekle
          </v-btn>
        </v-col>

        <v-col cols="6" md="6">
          <v-btn
            block
            color="primary"
            class="rounded-0 rounded-br-xl text-caption font-weight-bold"
            x-large
            @click.stop="isAddEditLensSidebar = !isAddEditLensSidebar"
          >
            <v-icon x-small left>
              {{ icons.mdiCircle }}
            </v-icon>
            Lens Ekle
          </v-btn>
        </v-col>
        <!--
        <v-col cols="4" md="4">
          <v-btn
            block
            color="primary"
            class="rounded-0 rounded-br-lg text-caption font-weight-bold"
            x-large
            @click.stop="isAddEditOtherProductsSidebar = !isAddEditOtherProductsSidebar"
          >
            <v-icon x-small left>
              {{ icons.mdiEyePlus }}
            </v-icon>
            Diğer Ürün Ekle
          </v-btn>
          <v-divider dark vertical />
        </v-col> -->
      </v-row>

      <!-- table -->

      <v-data-table :headers="tableColumns" :items="order.items" hide-default-footer>
        <template v-slot:item.prd_code="{ item }">
          <v-chip small label>
            <span class="text-caption font-weight-medium">{{ item.prd_code }}</span>
          </v-chip>
        </template>
        <template v-slot:item.title="{ item }">
          <div class="text-body-2">
            {{ item.title }}
          </div>
          <div v-if="item.discountMsg" class="text-caption warning--text">
            {{ item.discountMsg }}
          </div>
        </template>
        <template v-slot:item.qty="{ item }"> {{ item.qty }} Adet </template>
        <template v-slot:item.price="{ item }">
          <span class="font-weight-bold">{{ item.price | currency({ symbol: item.currency }) }}</span>
        </template>
        <template v-slot:item.discount="{ item }">
          {{ item.discount }}
        </template>
        <template v-slot:item.tax="{ item }">
          <div>{{ item.tax | currency({ symbol: item.currency }) }}</div>
          <div style="font-size: 10px">KDV Oran : %{{ item.tax_rate }}</div>
        </template>
        <template v-slot:item.rowAmount="{ item }">
          <div>{{ item.rowAmount | currency({ symbol: item.currency }) }}</div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="order.removeItem(item.id)">
            <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <v-card-actions v-if="order.rawItems.length > 0" class="white pt-12">
        <v-row>
          <v-col cols="12" md="8"></v-col>
          <v-col cols="12" md="4" class="text-body-2">
            <div class="d-flex justify-space-between">
              <div class="">Toplam</div>
              <div>{{ order.subTotal | currency }}</div>
            </div>
            <v-divider class="my-3" />
            <div class="d-flex justify-space-between">
              <div>Toplam İskonto</div>
              <div>{{ order.discountPrice | currency }}</div>
            </div>
            <v-divider class="my-3" />

            <div class="d-flex justify-space-between">
              <div>Dip İskonto İndirimi</div>
              <div>{{ order.dipdiscountPrice | currency }}</div>
            </div>
            <v-divider class="my-3" />
            <div class="d-flex justify-space-between">
              <div>Alt Toplam</div>
              <div>{{ order.subTotals | currency }}</div>
            </div>
            <v-divider class="my-3" />

            <div class="d-flex justify-space-between">
              <div>Vergiler Toplamı</div>
              <div>{{ order.taxes | currency }}</div>
            </div>
            <v-divider class="my-3" />

            <div class="d-flex justify-space-between">
              <div class="font-weight-bold">Genel Toplam</div>
              <div>{{ order.total | currency }}</div>
            </div>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-actions v-if="order.rawItems.length > 0" class="white pt-6">
        <v-spacer />
        <v-btn class="text-capitalize" :disabled="loading" plain @click="order.rawItems = []"> Temizle </v-btn>
        <v-btn
          class="text-capitalize"
          :loading="loading"
          :disabled="loading || isOrderButtonDisabled"
          rounded
          color="accent"
          x-large
          @click="submitForm"
        >
          Sipariş Ver
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="snackbar" :color="snackbarColor" elevation="12" rounded="pill" :timeout="5000">
      <div class="text-h6 text-center">
        {{ snackbarTXT }}
      </div>
    </v-snackbar>
  </div>
  <div v-else>
    <customer-list v-if="userData.profile === '30' && $vuetify.breakpoint.xsOnly" />
    <v-alert text outlined class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption" type="warning">
      Lütfen yukarıdan bir müşteri seçin
    </v-alert>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import axios from '@axios'
import {
  mdiAccountOutline,
  mdiCircle,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyePlus,
  mdiFileDocumentOutline,
  mdiSunglasses,
  mdiTableLargePlus,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'

// sidebar
import GlassAddEdit from './GlassAddEdit.vue'
import LensAddEdit from './LensAddEdit.vue'
import OtherProductsAddEdit from './OtherProductsAddEdit.vue'

import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import { useOrderStore } from '@/stores/order'

export default {
  components: {
    GlassAddEdit,
    LensAddEdit,
    OtherProductsAddEdit,
    CustomerList,
  },
  setup() {
    const order = useOrderStore()
    const form = ref(null)
    const refNo = ref(null)
    const address = ref(null)
    const description = ref(null)
    const addressItems = ref([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const status = ref(false)
    const shipmentMethod = ref('subeye_kargo')

    if (userData.profile === '30' && store.state.plasiyerCustomer === null) {
      status.value = false
    } else {
      status.value = true
    }

    onMounted(() => {
      const params = new URLSearchParams()
      params.append('method', 'getCustomerAddressList')

      axios.post('', params).then(response => {
        if (response.data.error) {
          return
        }
        addressItems.value = response.data.detail
        address.value = response.data.detail[0].id
      })
      if (order.items.length > 0) {
        if (order.items[0].customer_id !== userData.customer_id) {
          order.rawItems = []
        }
      }
    })

    const userListTable = ref([])
    const tableColumns = [
      {
        text: 'Kod',
        value: 'prd_code',
        width: '100',
        sortable: false,
      },
      { text: 'Ürün', value: 'title', align: 'stat min-width-300' },
      {
        text: 'Birim Fiyat',
        value: 'price',
        width: '125',
        align: 'end',
        sortable: false,
      },
      {
        text: 'Miktar',
        value: 'qty',
        width: '100',
        align: 'end',
        sortable: false,
      },
      {
        text: 'İskonto',
        value: 'discount',
        width: '150',
        align: 'end',
        sortable: false,
      },
      {
        text: 'KDV',
        value: 'tax',
        width: '125',
        align: 'end',
        sortable: false,
      },
      {
        text: 'Toplam',
        value: 'rowAmount',
        width: '125',
        align: 'end',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        width: '10',
        align: 'end',
        sortable: false,
      },
    ]

    const isAddEditGlassSidebar = ref(false)
    const isAddEditLensSidebar = ref(false)
    const isAddEditOtherProductsSidebar = ref(false)

    const loading = ref(false)
    const snackbar = ref(false)
    const snackbarTXT = ref('')
    const snackbarColor = ref(null)

    //* ** ESKI 503 hatası alan
    /*   const submitForm = async () => {
      loading.value = true
      const params = new URLSearchParams()
      params.append('method', 'saveOrder')
      params.append('description', description.value)
      params.append('address', address.value)
      params.append('lines', JSON.stringify(order.lines))
      axios
        .post('', params)
        .then(response => {
          if (typeof response.data === 'string') {
            snackbarColor.value = 'error'
            snackbarTXT.value = 'Kayıt Başarısız'
            console.log(response)

            return
          }
          if (response.data.error) {
            snackbarColor.value = 'error'
            snackbarTXT.value = response.data.msg
            console.log(response)

            return
          }
          order.rawItems = []
          refNo.value = ''
          description.value = ''
          address.value = ''
          form.value.reset()
          snackbarColor.value = 'success'
          snackbarTXT.value = 'Kayıt Başarılı'
          console.log(response)
        })
        .catch(e => {
          snackbarColor.value = 'error'
          snackbarTXT.value = e
          console.log(e)
          console.log(response)
        })
        .finally(() => {
          loading.value = false
          snackbar.value = true
        })
    } */
    // GMZ UPDATE
    const isOrderButtonDisabled = ref(false) // Butonun durumunu kontrol eden değişken
    const processingOrder = ref(false) // İşlem durumu
    const showSnackbar = (color, message) => {
      snackbarColor.value = color
      snackbarTXT.value = message
      snackbar.value = true
    }

    const resetForm = () => {
      order.rawItems = []
      refNo.value = ''
      description.value = ''
      address.value = ''
      form.value.reset()
    }

    const handleError = e => {
      if (e.response) {
        /* if (e.response.status === 503 || e.response.status === 500) {
          showSnackbar(
            'error',
            'Sistemsel bir hata oluştu, lütfen siparişinizi ilgili sipariş takip ekranından kontrol ediniz.',
          )
        } else {
          showSnackbar('error', `Hata: ${e.response.status} - ${e.response.data.msg || e.message}`)
        } */

        // 200 ve 201 dışındaki durum kodları için hata mesajı
        if (e.response.status !== 200 && e.response.status !== 201) {
          showSnackbar(
            'error',
            'Sistemsel bir hata oluştu, lütfen siparişinizi ilgili sipariş takip ekranından kontrol ediniz.',
          )
        } else {
          showSnackbar('error', `Hata: ${e.response.status} - ${e.response.data.msg || 'Beklenmeyen bir hata oluştu.'}`)
        }
      } else if (e.code === 'ECONNABORTED') {
        showSnackbar(
          'error',
          'İşlem zaman aşımına uğradı. Lütfen siparişinizi ilgili sipariş takip ekranından kontrol ediniz.',
        )
      } else {
        showSnackbar('error', `Hata: ${e.message}`)
      }
      console.log('try-catch', e)

      // Hata mesajı gösterildikten sonra butonu 30 saniye devre dışı bırak
      isOrderButtonDisabled.value = true
      setTimeout(() => {
        isOrderButtonDisabled.value = false // 30 saniye sonra butonu tekrar aktif et
      }, 30000)
    }

    const submitForm = async () => {
      if (processingOrder.value) {
        return
      } // Eğer işlem devam ediyorsa çık

      loading.value = true
      isOrderButtonDisabled.value = true // Butonu devre dışı bırak
      processingOrder.value = true // İşlem başladığını belirt

      const params = new URLSearchParams()
      params.append('method', 'saveOrder')
      params.append('description', description.value)
      params.append('shipmentMethod', shipmentMethod.value)
      params.append('address', address.value)
      params.append('lines', JSON.stringify(order.lines))

      try {
        const response = await axios.post('', params, { timeout: 60000 }) // Zaman aşımını 60 saniye olarak ayarladık

        // 200 ve 201 dışındaki durum kodları için hata mesajı
        if (response.status !== 200 && response.status !== 201) {
          showSnackbar('error', `Hata: ${response.status} - Beklenmeyen bir durum oluştu.`)

          return
        }

        if (typeof response.data === 'string') {
          showSnackbar('error', 'Kayıt Başarısız')
          console.log('response', response)

          return
        }

        if (response.data.error) {
          showSnackbar('error', response.data.msg)
          console.log('response', response)

          return
        }

        // Başarılı işlem sonrası sıfırlama
        resetForm()
        showSnackbar('success', 'Kayıt Başarılı')
        console.log('kayıt başarılı response', response)
      } catch (e) {
        handleError(e)
        console.log('try-catch', e)
      } finally {
        loading.value = false

        // İşlem tamamlandığında geri al
        processingOrder.value = false // İşlem durumu her durumda sıfırlanır
        isOrderButtonDisabled.value = false // Butonu tekrar aktif et
      }
    }

    watch(
      () => order.lines,
      async () => {
        console.log('Ekran1')
        console.log(order)

        const params = new URLSearchParams()
        params.append('method', 'saveBasket')
        params.append('lines', JSON.stringify(order.lines))
        axios.post('', params)
      },
    )

    watch(
      () => store.state.plasiyerCustomer,
      newValue => {
        if (newValue) {
          status.value = true
        }
      },
    )

    return {
      order,
      userListTable,
      tableColumns,

      isAddEditGlassSidebar,
      isAddEditLensSidebar,
      isAddEditOtherProductsSidebar,
      addressItems,
      form,
      address,
      description,
      refNo,
      submitForm,
      snackbar,
      snackbarTXT,
      snackbarColor,
      loading,
      status,
      userData,
      resetForm,
      showSnackbar,
      handleError,
      isOrderButtonDisabled,
      processingOrder,
      shipmentMethod,

      // icons
      icons: {
        mdiTableLargePlus,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiCircle,
        mdiSunglasses,
        mdiAccountOutline,
        mdiEyePlus,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
.v-data-table-header-mobile {
  display: none;
}
.theme--light.v-data-table th {
  border-top: none !important;
}

.white-bg .v-input__slot {
  background-color: white !important;
}
</style>
