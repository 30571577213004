var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{class:_vm.$vuetify.breakpoint.xsOnly ? 'rounded-0' : 'rounded-l-xl',attrs:{"value":_vm.isAddEditGlassSidebar,"temporary":"","touchless":"","floating":"","right":"","app":"","stateless":"","width":_vm.$vuetify.breakpoint.xsOnly ? '100%' : 450},on:{"input":function (val) { return _vm.$emit('update:is-add-edit-glass-sidebar', val); }},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.xsOnly)?{key:"append",fn:function(){return [_c('v-container',[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"plain":"","small":"","block":""},on:{"click":_vm.closeSidebar}},[_vm._v(" Vazgeç ")])],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-btn',{staticClass:"me-3",attrs:{"color":"primary","rounded":"","x-large":"","block":"","disabled":_vm.stockDisabled || _vm.isSubmitting},on:{"click":_vm.submitForm}},[_vm._v(" Ekle ")])],1)],1)],1)]},proxy:true}:null],null,true)},[_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card-title',{staticClass:"px-4 thead-color"},[_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"50","color":"primary"}},[_c('v-icon',{attrs:{"color":"white","size":30}},[_vm._v(" "+_vm._s(_vm.icons.mdiSunglasses)+" ")])],1),_c('span',{staticClass:"font-weight-semibold text-base primary--text"},[_vm._v("Cam Ekle")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.closeSidebar}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)],1),_c('ValidationObserver',{ref:"form",attrs:{"tag":"div"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"rounded-0",attrs:{"items":_vm.items.brand,"label":"Marka","item-text":"branddesc","item-value":"branddesc","readonly":!_vm.formField.reverse,"hide-details":"","attach":"","eager":"","filled":"","error-messages":errors,"menu-props":{ bottom: true, offsetY: true }},on:{"focus":function($event){return _vm.brandFocus()},"change":function($event){return _vm.brandChange()}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-alert',{staticClass:"ma-0 rounded-0",attrs:{"type":_vm.loadingSelectStatus,"color":!_vm.loadingSelectStatus ? 'primary' : null,"text":""},scopedSlots:_vm._u([(_vm.loadingSelectStatus === null)?{key:"prepend",fn:function(){return [_c('v-progress-circular',{staticClass:"mr-3",attrs:{"width":2,"size":25,"color":"primary","indeterminate":""}})]},proxy:true}:null],null,true)},[_vm._v(" "+_vm._s(_vm.loadingSelectText)+" ")])]},proxy:true}],null,true),model:{value:(_vm.formField.brand),callback:function ($$v) {_vm.$set(_vm.formField, "brand", $$v)},expression:"formField.brand"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"rounded-0",attrs:{"items":_vm.items.name,"disabled":_vm.items.brand.length === 0,"readonly":!_vm.formField.reverse,"label":"Ürün Adı","item-text":"stext","item-value":"stext","return-object":"","hide-details":"","attach":"","eager":"","filled":"","error-messages":errors,"menu-props":{ bottom: true, offsetY: true }},on:{"focus":function($event){return _vm.nameFocus()},"change":function($event){return _vm.nameChange()}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-alert',{staticClass:"ma-0 rounded-0",attrs:{"type":_vm.loadingSelectStatus,"color":!_vm.loadingSelectStatus ? 'primary' : null,"text":""},scopedSlots:_vm._u([(_vm.loadingSelectStatus === null)?{key:"prepend",fn:function(){return [_c('v-progress-circular',{staticClass:"mr-3",attrs:{"width":2,"size":25,"color":"primary","indeterminate":""}})]},proxy:true}:null],null,true)},[_vm._v(" "+_vm._s(_vm.loadingSelectText)+" ")])]},proxy:true}],null,true),model:{value:(_vm.formField.name),callback:function ($$v) {_vm.$set(_vm.formField, "name", $$v)},expression:"formField.name"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"rounded-0",attrs:{"items":_vm.items.sph,"disabled":_vm.items.name.length === 0,"label":"SPH","hide-details":"","attach":"","eager":"","filled":"","error-messages":errors,"menu-props":{ bottom: true, offsetY: true }},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-alert',{staticClass:"ma-0 rounded-0",attrs:{"type":_vm.loadingSelectStatus,"color":!_vm.loadingSelectStatus ? 'primary' : null,"text":""},scopedSlots:_vm._u([(_vm.loadingSelectStatus === null)?{key:"prepend",fn:function(){return [_c('v-progress-circular',{staticClass:"mr-3",attrs:{"width":2,"size":25,"color":"primary","indeterminate":""}})]},proxy:true}:null],null,true)},[_vm._v(" "+_vm._s(_vm.loadingSelectText)+" ")])]},proxy:true}],null,true),model:{value:(_vm.formField.sph),callback:function ($$v) {_vm.$set(_vm.formField, "sph", $$v)},expression:"formField.sph"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-0",attrs:{"items":_vm.items.cyl,"disabled":_vm.items.name.length === 0,"label":"CYL","hide-details":"","attach":"","eager":"","filled":"","error-messages":errors,"menu-props":{ bottom: true, offsetY: true }},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-alert',{staticClass:"ma-0 rounded-0",attrs:{"type":_vm.loadingSelectStatus,"color":!_vm.loadingSelectStatus ? 'primary' : null,"text":""},scopedSlots:_vm._u([(_vm.loadingSelectStatus === null)?{key:"prepend",fn:function(){return [_c('v-progress-circular',{staticClass:"mr-3",attrs:{"width":2,"size":25,"color":"primary","indeterminate":""}})]},proxy:true}:null],null,true)},[_vm._v(" "+_vm._s(_vm.loadingSelectText)+" ")])]},proxy:true}],null,true),model:{value:(_vm.formField.cyl),callback:function ($$v) {_vm.$set(_vm.formField, "cyl", $$v)},expression:"formField.cyl"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-0",attrs:{"items":_vm.items.direction,"label":"Cam Yönü","readonly":!_vm.formField.reverse,"hide-details":"","return-object":"","attach":"","eager":"","filled":"","error-messages":errors,"menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.formField.direction),callback:function ($$v) {_vm.$set(_vm.formField, "direction", $$v)},expression:"formField.direction"}})]}}])}),(_vm.formField.haswarrantycard != '2')?_c('v-select',{staticClass:"rounded-0",attrs:{"items":_vm.items.axe,"label":"Axe","hide-details":"","attach":"","eager":"","filled":"","menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.formField.axe),callback:function ($$v) {_vm.$set(_vm.formField, "axe", $$v)},expression:"formField.axe"}}):_vm._e(),(_vm.formField.haswarrantycard != '2')?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"readonly":!_vm.formField.reverse,"label":"Kullanıcı Adı","hide-details":"","filled":"","error-messages":errors},model:{value:(_vm.formField.fullname),callback:function ($$v) {_vm.$set(_vm.formField, "fullname", $$v)},expression:"formField.fullname"}})]}}],null,false,239747509)}):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-0",attrs:{"min":"1","type":"number","label":"Miktar","hide-details":"","filled":"","error-messages":errors},model:{value:(_vm.formField.qty),callback:function ($$v) {_vm.$set(_vm.formField, "qty", _vm._n($$v))},expression:"formField.qty"}})]}}])})],1)]),_c('v-divider'),(_vm.TESF > 0)?_c('div',[_c('v-alert',{staticClass:"text-caption ma-0",attrs:{"rounded":"0","type":"info","text":"","color":"primary"}},[_vm._v(" Birim Satış Fiyatı : "+_vm._s(_vm._f("currency")(_vm.formField.price))+" ")]),_c('v-divider'),_c('v-alert',{staticClass:"text-caption ma-0",attrs:{"rounded":"0","type":"info","text":"","color":"primary"}},[_vm._v(" Tavsiye Edilen Perakende Satış Fiyatı : "+_vm._s(_vm._f("currency")(_vm.formField.price_ps))+" ")])],1):_vm._e(),(_vm.STOCK_CONTROL)?_c('div',[_c('v-alert',{staticClass:"text-caption ma-0",attrs:{"rounded":"0","type":_vm.stockType,"text":""}},[_vm._v(" "+_vm._s(_vm.stockText)+" ")])],1):_vm._e()],1),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-container',{staticClass:"pb-16"},[_c('v-row',{staticClass:"pb-16",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"plain":"","small":"","block":""},on:{"click":_vm.closeSidebar}},[_vm._v(" Vazgeç ")])],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-btn',{staticClass:"me-3",attrs:{"color":"primary","rounded":"","x-large":"","block":"","disabled":_vm.stockDisabled || _vm.isSubmitting},on:{"click":_vm.submitForm}},[_vm._v(" Ekle ")])],1)],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"360","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.confrimText)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeSidebar}},[_vm._v(" Hayır ")]),_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.dialogConfrim}},[_vm._v(" Evet ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }